<template>
  <div class="add-pro-wrapper">
    <!-- 标题与保存按钮 -->
    <el-row>
      <el-col :span="12"><Title title="回答寻找"></Title></el-col>
      <el-col :span="12">
        <div style="width:100%;height:60px;padding-right:15px;box-sizing:border-box" class="flex_rec">
          <el-button type="primary" @click="save">保存</el-button>
        </div>
      </el-col>
    </el-row>

    <div class="add-pro-content">
      <el-row :gutter="15">
      <!-- 左模块 -->
        <el-col :span="18">
          <!-- 基本信息 -->
          <el-card class="box-card">
            <el-row :gutter="20">
              <!-- 左边 -->
              <el-col :span="4">
                <div class="part-info">
                  <div class="title">基本</div>
                  <div class="info">信息</div>
                </div>
              </el-col>
              <!-- 右边 -->
              <el-col :span="20">
                <el-row :gutter="20">
                  <el-col :span="12">
                      <el-input placeholder="请输入内容" v-model="form.id" :disabled="true">
                        <template slot="prepend">ID</template>
                      </el-input>
                  </el-col>
                  <el-col :span="12">
                      <el-input placeholder="请输入内容" v-model="form.username" :disabled="false">
                        <template slot="prepend">账号</template>
                      </el-input>
                  </el-col>
                </el-row>
                <!-- 查找内容 -->
                <el-row :gutter="20" class="mt10">
                  <el-col :span="24">
                    <p>
                      {{form.description}}
                    </p>
                  </el-col>
                </el-row>
                <!-- finder -->
                <el-row :gutter="20" class="mt10">
                  <el-col :span="24">
                      <el-input placeholder="请输入内容" v-model="form.finder" :disabled="false">
                        <template slot="prepend">查找人</template>
                      </el-input>
                  </el-col>
                </el-row>
                <!-- contact_way -->
                <el-row v-if="!isLinkType" :gutter="20" class="mt10">
                  <el-col :span="24">
                      <el-input placeholder="请输入内容" v-model="form.contact_way" :disabled="false">
                        <template slot="prepend">联系方式</template>
                      </el-input>
                  </el-col>
                </el-row>
                <!-- time -->
                <el-row v-if="!isLinkType" :gutter="20" class="mt10">
                    <el-col :span="24">
                        <el-input placeholder="请输入内容" v-model="form.time" :disabled="false">
                          <template slot="prepend">时间</template>
                        </el-input>
                    </el-col>
                </el-row>
                <!-- 内容描述 -->
                <el-input
                  type="textarea"
                  style="margin-top:10px"
                  :autosize="{ minRows: 4, maxRows: 6}"
                  placeholder="请输入回复内容"
                  v-model="form.answer">
                </el-input>

              </el-col>
            </el-row>
          </el-card>
        </el-col>

        <!-- 右模块 -->
        <el-col :span="6">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>状态</span>
            </div>
            <p class="p-extra mb5">回答状态</p>
            <el-select v-model="form.is_answer" placeholder="请选择回答状态" style="width:100%" class="mb10">
              <el-option
                v-for="item in status_arr"
                :key="item.value"
                :label="item.name"
                :value="item.value">
              </el-option>
            </el-select>
            <p class="p-extra mb5">阅读状态</p>
            <el-select v-model="form.is_read" placeholder="请选择阅读状态" style="width:100%" class="mb10">
              <el-option
                v-for="item in status_arr"
                :key="item.value"
                :label="item.name"
                :value="item.value">
              </el-option>
            </el-select>
          </el-card>
        </el-col>
      </el-row>
    </div>

  </div>
</template>

<script>
import Title from "./components/Title"
import apis from "@/api/index"
export default {
  name:'addProducts',
    components:{
      Title
    },
    data(){
      return{
        form:{
          answer:"",
          is_read:0,
          is_answer:0,
        },
        status_arr:[
          {name:'是',value:1},
          {name:'否',value:0},
          {name:'未设置',value:null},
        ],
      }
    },
    methods:{
      save(){
        console.log(this.form)
        const answer = {
          answer:this.form.answer,
          is_read:this.form.is_read,
          is_answer:this.form.is_answer,
          id:this.form.id
        }
        apis.findSound.answer(answer).then(res=>{
          console.log('res',res)
          if(res.data.code == 1){
            this.$message({message:"保存成功", type:"success"})
            this.$router.go(-1)
          }else{
            this.$message({message:res.data.msg,type:'error'})
          }

        }).catch(err=>{
          this.$message({message:err,type:'error'})
        })
      },
    },
    computed:{

    },
    mounted(){
      console.log('mounted',this.$route)
      const params = this.$route.params.obj
      Object.keys(params).forEach(key=>{
        this.$set(this.form,key,params[key])
      })
      // Object.assign(this.form,params)
    },
    created(){
      console.log('created',this.$route)
    },
}
</script>

<style lang="scss">
.add-pro-wrapper{
  .add-pro-content{
    box-sizing: border-box;
    width: 100%;
    padding:15px;
  }
  .box-card{
    margin-bottom:5px;
  }
  .part-info{
    // @include flex_ccc();
    // background: brown;
    .title{
      font-size: 18px;
      color:#333;
    }
    .info{
      font-size: 12px;
      color:#999;
    }
  }
}
</style>